const textPosition = () => {
    const sectionsConfig = [
        {
            shapeId: 'shape-1',
            sectionSelector: '.section-1',
            columns: [
                { selector: '.column', percentage: 10.826411795095974 }
            ]
        },
        {
            shapeId: 'shape-2',
            sectionSelector: '.section-2',
            columns: [
                { selector: '.column-left', percentage: 65 },  //25 // Original: 25 or 33.337691201464244
                { selector: '.column-right', percentage: 15 }
            ]
        },
        {
            shapeId: 'shape-3',
            sectionSelector: '.section-3',
            columns: [
                { selector: '.column-left', percentage: 0},
                { selector: '.column-right', percentage: window.innerWidth > 1800 ? 70 : 60 } // 70 Original: 85.612029455687729
            ]
        },
        {
            shapeId: 'shape-4',
            sectionSelector: '.section-4',
            columns: [
                { selector: '.column-left', percentage: 55 }, // Original: 86.307351674935422
                { selector: '.column-right', percentage: 90 } //30 // Original: 42.025093279757268
            ]
        },
        {
            shapeId: 'shape-5',
            sectionSelector: '.section-5',
            columns: [
                { selector: '.column-right', percentage: 115 } // 105 95.194288342699438
            ]
        },
        {
            shapeId: 'shape-6',
            sectionSelector: '.section-6',
            columns: [
                { selector: '.column-left', percentage: 85 }, // Original: 126.072243203829039
                { selector: '.column-right', percentage: 140 } // 122.886362786523576
            ]
        }
    ];

    sectionsConfig.forEach(({ shapeId, sectionSelector, columns }) => {
        const shape = document.getElementById(shapeId);
        const section = document.querySelector(sectionSelector);

        if (!shape) {
            console.warn(`Element with ID '${shapeId}' not found.`);
            return;
        }

        if (!section) {
            console.warn(`Element with selector '${sectionSelector}' not found.`);
            return;
        }

        const shapeHeight = shape.getBoundingClientRect().height;
        section.style.height = `${shapeHeight}px`;

        columns.forEach(({ selector, percentage }) => {
            const column = section.querySelector(selector);
            if (!column) {
                console.warn(`Column with selector '${selector}' not found in '${sectionSelector}'.`);
                return;
            }

            const distance = (percentage / 100) * shapeHeight;
            column.style.transform = `translate(0, ${distance}px)`;
        });
    });

    const serviceShapeDesktop = document.querySelector('.service-shape-desktop'),
          serviceArrowDesktop = document.querySelector('.service-arrows-desktop'),
          serviceShapeDesktopRect = serviceShapeDesktop.getBoundingClientRect(),
          arrowsTopDistance = (28.556782466932217 / 100) * serviceShapeDesktopRect.height;

    serviceArrowDesktop.style.top = `${arrowsTopDistance}px`;
};

const initTextPosition = () => {
    if (window.innerWidth > 992) {
        textPosition();
    } else {
        resetTextPosition();
    }
};

const resetTextPosition = () => {
    const sectionsConfig = [
        '.section-1',
        '.section-2',
        '.section-3',
        '.section-4',
        '.section-5',
        '.section-6'
    ];

    sectionsConfig.forEach(sectionSelector => {
        const section = document.querySelector(sectionSelector);
        if (section) {
            const columns = section.querySelectorAll('[class*="column"]');
            columns.forEach(column => {
                column.style.transform = 'translate(0, 0)';
            });
            section.style.height = 'auto';
        }
    });
};

const debounce = (func, wait = 100) => {
    let timeout;
    return (...args) => {
        const later = () => {
            clearTimeout(timeout);
            func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

//document.addEventListener('DOMContentLoaded', initTextPosition);

//window.addEventListener('resize', debounce(initTextPosition, 200));

export default initTextPosition;