import { gsap } from 'gsap';
/**
 * Initializes header animations and interactions.
 * @param {Object} scroll - An object with a scrollTo method for scrolling actions.
 * @returns {Object} An object containing a destroy method to remove event listeners.
 */
const header = (scroll) => {
    // Define selectors and constants
    const SELECTORS = {
        body: 'body',
        logoSVG: '#logo svg',
        header: '.header',
        headerScrolledClass: 'scrolled',
        headerLinks: '.header a',
        logoShape: '#logo-shape',
        logoArrow: '#logo-arrow',
        mainVideo: '.main-shape-video',
        mainPlay: '.main-play'
    };

    // Cache DOM elements for better performance
    const body = document.querySelector(SELECTORS.body);
    const logoSVG = document.querySelector(SELECTORS.logoSVG);
    const headerElement = document.querySelector(SELECTORS.header);
    const headerLinks = document.querySelectorAll(SELECTORS.headerLinks);
    const logoShape = document.querySelector(SELECTORS.logoShape);
    const logoArrow = document.querySelector(SELECTORS.logoArrow);
    const mainShapeVideo = document.querySelector(SELECTORS.mainVideo);
    const mainPlayVideo = document.querySelector(SELECTORS.mainPlay);

    // Exit early if essential elements are not found
    if (!logoSVG || !headerElement || !logoShape || !logoArrow) {
        console.warn('One or more header elements are missing in the DOM.');
        return;
    }

    /**
     * Handles the mouse enter event on the logo SVG.
     */
    const handleMouseEnter = () => {
        if (headerElement.classList.contains(SELECTORS.headerScrolledClass)) {
            gsap.to(logoShape, {
                duration: 0.4,
                morphSVG: logoArrow, // Morph to the target shape
                ease: 'power4.inOut',
                scale: 1.2
            });
        }
    };

    /**
     * Handles the mouse leave event on the logo SVG.
     */
    const handleMouseLeave = () => {
        if (headerElement.classList.contains(SELECTORS.headerScrolledClass)) {
            gsap.to(logoShape, {
                duration: 0.4,
                morphSVG: logoShape, // Morph back to the original shape
                ease: 'power4.inOut',
                scale: 1
            });
        }
    };

    /**
     * Handles the click event on header links.
     * Scrolls to the top and morphs the logo shape back to its original form.
     * @param {Event} event - The click event object.
     */
    const handleHeaderLinkClick = (event) => {
        event.preventDefault(); // Prevent default anchor behavior if necessary

        // Scroll to the top
        if (typeof scroll.scrollTo === 'function') {
            scroll.scrollTo(0);
        } else {
            console.warn('scroll.scrollTo is not a function.');
        }

        // Morph the logo shape back to its original form
        gsap.to(logoShape, {
            duration: 0.4,
            morphSVG: logoShape, // Morph back to the original shape
            ease: 'power4.inOut',
            scale: 1
        });
    };

    /**
     * Initializes event listeners for header interactions.
     */
    const initializeEventListeners = () => {
        // Handle mouse enter and leave on the logo SVG
        logoSVG.addEventListener('mouseenter', handleMouseEnter);
        logoSVG.addEventListener('mouseleave', handleMouseLeave);

        // Handle click events on all header links
        headerLinks.forEach(link => {
            link.addEventListener('click', handleHeaderLinkClick);
        });
    };

    /**
     * Cleans up event listeners to prevent memory leaks.
     */
    const removeEventListeners = () => {
        // Remove mouse enter and leave listeners
        logoSVG.removeEventListener('mouseenter', handleMouseEnter);
        logoSVG.removeEventListener('mouseleave', handleMouseLeave);

        // Remove click listeners from header links
        headerLinks.forEach(link => {
            link.removeEventListener('click', handleHeaderLinkClick);
        });
    };

    /**
     * Initializes the header functionality.
     */
    const init = () => {
        initializeEventListeners();

        // Optional: Handle dynamic class changes if the header can scroll dynamically
        // For example, listening to scroll events to add/remove the 'scrolled' class
        // This part depends on how the 'scrolled' class is managed in your application
    };

    const scrollToVideo = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Ensure 'scroll' is defined and initialized
        if (typeof scroll !== 'undefined') {
            const targetElement = document.getElementById('video-full');
            if (targetElement) {
                document.body.classList.add('scroll-show');
                scroll.scrollTo(targetElement);
            } else {
                console.warn('Target element #video-full not found.');
            }
        } else {
            console.error('Locomotive Scroll is not initialized.');
        }
    };

    mainShapeVideo.addEventListener('click', scrollToVideo);
    mainPlayVideo.addEventListener('click', scrollToVideo);

    // Execute initialization
    init();

    // Optional: Return a cleanup function if needed elsewhere
    return {
        destroy: removeEventListeners
    };
};

export default header;
