import LocomotiveScroll from 'locomotive-scroll';

const locomotiveInit = () => {
    const scrollContainer = document.querySelector('[data-scroll-container]');
    const header = document.querySelector('.header');
    const scrollThreshold = 50;

    if (!scrollContainer) {
        console.warn("Scroll container not found!");
        return null;
    }

    // Initialize Locomotive Scroll with enhanced configuration
    const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        direction: 'vertical',
        class: 'visible',
        smartphone: {
            smooth: true,
            // Additional smartphone-specific settings
        },
        tablet: {
            smooth: true,
            // Additional tablet-specific settings
        }
    });

    // Update scroll after DOM is fully loaded
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', scroll.update);
    } else {
        scroll.update();
    }

    // Handle header state based on scroll position
    const handleScroll = ({ scroll }) => {
        const isScrolled = scroll.y > scrollThreshold;
        header.classList.toggle('scrolled', isScrolled);
    };

    scroll.on("scroll", handleScroll);

    // Lazy load videos using Intersection Observer
    const lazyLoadVideos = () => {
        const lazyVideos = scrollContainer.querySelectorAll("video.lazy-video");

        if ('IntersectionObserver' in window) {
            const videoObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const video = entry.target;
                        const dataSrc = video.dataset.src;
                        if (dataSrc && !video.src) {
                            video.src = dataSrc;
                            video.load();
                            video.classList.remove('lazy-video'); // Optional: Remove class after loading
                            observer.unobserve(video);
                        }
                    }
                });
            }, {
                root: scrollContainer,
                rootMargin: '0px',
                threshold: 0.1 // Adjust as needed
            });

            lazyVideos.forEach(video => {
                videoObserver.observe(video);
            });
        } else {
            // Fallback for browsers that do not support Intersection Observer
            lazyVideos.forEach(video => {
                const dataSrc = video.dataset.src;
                if (dataSrc && !video.src) {
                    video.src = dataSrc;
                    video.load();
                }
            });
        }
    };

    lazyLoadVideos();

    const footerNavigation = () => {
        document.querySelectorAll('.footer-menu a[href^="#"]').forEach(el => {
            el.addEventListener('click', function(e) {
                e.preventDefault();
                const targetId = this.getAttribute('href').substring(1);
                const targetElement = document.getElementById(targetId);
        
                if (targetElement) {
                    scroll.scrollTo(targetElement);
                }
            });
        });
    };
    
    // Initialize the function
    footerNavigation();
    
    return scroll;
};

export default locomotiveInit;
