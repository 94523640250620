const cursor = () => {
    const circleCursor = document.querySelector('.circle-cursor');
    const hoverTargets = '.more, footer a, .swiper-button-prev, .swiper-button-next, .modal-close, .tab-title, .sitemap-item, .career-more, .modal-form input, .modal-form button, .swiper-pagination-bullet, .box';
    const videoShape = '.main-shape-video, .video-full, .video-full-us';

    let lastMouseMoveEvent = null;
    
    const debounceMouseMove = (e) => {
        lastMouseMoveEvent = e;
        if (!circleCursor._moving) {
            circleCursor._moving = true;
            requestAnimationFrame(() => {
                circleCursor.style.left = `${lastMouseMoveEvent.clientX}px`;
                circleCursor.style.top = `${lastMouseMoveEvent.clientY}px`;
                circleCursor._moving = false;
            });
        }
    };

    document.addEventListener('mousemove', debounceMouseMove);

    document.addEventListener('mouseover', (e) => {
        if (e.target.matches(hoverTargets) || e.target.closest(hoverTargets)) {
            circleCursor.classList.add('hover-target');
        } else if (e.target.matches(videoShape) || e.target.closest(videoShape) ) {
            circleCursor.classList.add('play-cursor');
        }
    });

    document.addEventListener('mouseout', (e) => {
        if (e.target.matches(hoverTargets) || e.target.closest(hoverTargets) || e.target.matches(videoShape) || e.target.closest(videoShape)) {
            circleCursor.classList.remove('hover-target');
            circleCursor.classList.remove('play-cursor');
        }
    });
};

export default cursor;
