const scaleShape = () => {
    const item = document.querySelector('.main-shape-video');
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;

    // Early return if the element does not exist
    if (!item) {
        console.warn("Element '.main-shape-video' not found.");
        return;
    }

    let itemHeight;
    if (isPortrait === true ) {
        itemHeight = item.offsetHeight * 2;
    } else {
        itemHeight = item.offsetHeight ; 
    }

    const scaleFactor = window.innerHeight / itemHeight;

    // Only apply the transform if it differs from the current scale
    const currentScale = getComputedStyle(item).transform,
        targetScale = `matrix(${scaleFactor}, 0, 0, ${scaleFactor}, 0, 0)`,
        windowWidth = $(window).width(),
        windowWidthVw = 100,
        shapeWidth = $(".services-shape").outerWidth(),
        shapeWidthVw = shapeWidth / windowWidth * 100,
        shapeOffsetLeft = $(".services-shape").offset().left,
        shapeOffsetLeftVw = shapeOffsetLeft / windowWidth * 100,
        shapeOffsetRightSpaceVw = windowWidthVw - shapeOffsetLeftVw,
        shapeRightOffsetRightRemainVw = shapeOffsetRightSpaceVw - shapeWidthVw,
        shapeRightDistancePointVw = shapeRightOffsetRightRemainVw + 7.447916666666667,

        mainShapeVideoWidth = $(".main-shape-video").width(),
        mainShapeVideoWidthScale = mainShapeVideoWidth * scaleFactor,
        mainShapeVideoWidthVw = mainShapeVideoWidthScale / windowWidth * 100,
        mainShapePointPercentage = mainShapeVideoWidthVw * 0.169678;


    if (currentScale !== targetScale) {
        item.style.transform = `scale(${scaleFactor})`;
        item.style.right = `${shapeRightDistancePointVw - mainShapePointPercentage - 0.5}vw`;
    }

    if (window.innerWidth < 992) {
        item.style.removeProperty('transform'); 
        item.style.removeProperty('right');
        return; 
    }

    // Add event listeners to handle window resizing and loading
    //window.addEventListener('resize', scaleToFit);
};

export default scaleShape;
