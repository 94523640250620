import "@babel/polyfill";
import loader from './__modules/loader';
import tab from './__modules/tab';
import locomotiveInit from './__modules/locomotive';
import cursor from './__modules/cursor';
import modal from './__modules/modal';
import updateViewport from './__modules/updateViewport';
import scaleShape from './__modules/scaleShape';
import equalContentHeight from './__modules/equalContentHeight';
import service from './__modules/services';
import careers from "./__modules/careers";
import careerSwiper from './__modules/careerSwiper';
import notFound from "./__modules/notFound";
import adjustScrollOffset from "./__modules/adjustScrollOffset";
import header from "./__modules/header";
import video from "./__modules/video";
import textPosition from "./__modules/textPosition";
import { gsap } from 'gsap';
import { debounce } from "./__modules/debounce";

let scroll;
const DEBOUNCE_DELAY = 400;
const handleResize = debounce(() => {
	scaleShape();
	service();
	textPosition();
	equalContentHeight();

	if (scroll && typeof scroll.update === 'function') {
		scroll.update();
	} else {
		console.error("Scroll object is not initialized or update method is missing.");
	}
}, DEBOUNCE_DELAY);

document.addEventListener('DOMContentLoaded', () => {
	cursor();

	if (document.querySelector('.tab')) {
		tab();
	}

	if (!localStorage.getItem("loaded") && document.querySelector('.loader')) {
		gsap.set(".loader-counter", { className: "+= loader-counter loader-counter--visible" });
		gsap.set(".loader-counter", { className: "+= loader-counter loader-counter--visible loader-counter--hide" }, 0.15);
	}

	modal();

	adjustScrollOffset();
});

window.addEventListener('load', () => {
	if (document.querySelector('.main-shape-video')) {
		scaleShape();
	}

	if (document.querySelector('.loader')) {
		textPosition();
		equalContentHeight();
		service();
		scroll = locomotiveInit();
		updateViewport();
		loader();
		header(scroll);
		video();
		scroll.update();
	}

	if (document.querySelector('.career')) {
		careers();
		careerSwiper();
		scroll = locomotiveInit();
		header(scroll);
	}

	if (document.querySelector('.notfound')) {
		notFound();
		locomotiveInit();
	}

});

// Remove unused event listeners unless needed later
window.addEventListener('resize', () => {
	if (document.querySelector('.loader')) {
		handleResize();
	}
});
// window.addEventListener('scroll', () => {});