/**
 * Adjusts the scroll offset of specific elements based on the window width.
 */
const adjustScrollOffset = () => {
    // Constants
    const FOOTER_SELECTOR = ".footer-top";
    const OFFSET_ATTRIBUTE = "data-scroll-offset";
    const MOBILE_BREAKPOINT = 768;
    const LARGE_SCREEN_BREAKPOINT = 1920;

    const OFFSET_VALUES = {
        LARGE_SCREEN: "10%",
        DEFAULT: "30%",
        MOBILE: "10%"
    };

    /**
     * Debounce function to limit how often a function can fire.
     * @param {Function} func - The function to debounce.
     * @param {number} wait - The number of milliseconds to wait before invoking the function.
     * @returns {Function} - The debounced function.
     */
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    /**
     * Retrieves the current window width.
     * @returns {number} The width of the window.
     */
    const getWindowWidth = () => window.innerWidth;

    /**
     * Sets the scroll offset for a given element based on the window width.
     * @param {Element} element - The DOM element to set the offset for.
     * @param {string} offset - The offset value to set.
     */
    const setScrollOffset = (element, offset) => {
        element.setAttribute(OFFSET_ATTRIBUTE, offset);
    };

    /**
     * Updates the scroll offsets for all relevant elements.
     */
    const updateOffsets = () => {
        const windowWidth = getWindowWidth();
        const footerTop = document.querySelector(FOOTER_SELECTOR);
        if (!footerTop) {
            console.warn(`Element with selector "${FOOTER_SELECTOR}" not found.`);
            return;
        }

        // Determine and set offset for the footer-top element
        const footerOffset = windowWidth > LARGE_SCREEN_BREAKPOINT
            ? OFFSET_VALUES.LARGE_SCREEN
            : OFFSET_VALUES.DEFAULT;
        setScrollOffset(footerTop, footerOffset);

        // Determine offset for non-footer elements based on window width
        const isMobile = windowWidth <= MOBILE_BREAKPOINT;
        const nonFooterOffset = isMobile ? OFFSET_VALUES.MOBILE : OFFSET_VALUES.DEFAULT;

        // Select all elements with data-scroll-offset except footer-top
        const allElements = document.querySelectorAll(`[${OFFSET_ATTRIBUTE}]:not(${FOOTER_SELECTOR})`);

        allElements.forEach(element => {
            setScrollOffset(element, nonFooterOffset);
        });
    };

    // Initialize offsets on function call
    updateOffsets();

    // Update offsets on window resize with debounce
    window.addEventListener("resize", debounce(updateOffsets, 200));
};

// Export the optimized function
export default adjustScrollOffset;
