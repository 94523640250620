// Import GSAP
import { gsap } from 'gsap';

// Define the video function
const video = () => {
    // Select DOM elements
    const videoElement = document.getElementById('video-full');
    const videoElementMedia = document.getElementById('video-full-media');
    const playCursor = document.querySelector('.circle-cursor');
    const videoFullUs = document.querySelector('.video-full-us');
    const videoFullPoster = document.querySelector('.video-full-poster');
    const videoFullVideo = document.querySelector('.video-full video');
    const videoFullPlay = document.querySelector('.video-full-play');

    if (!videoFullVideo) {
        console.error('Video element not found');
        return;
    }

    // Initialize GSAP timeline
    const videoTl = gsap.timeline({ paused: true });
    let videoStarted = false;

    videoTl
        .to(videoFullUs, {
            opacity: 0,
            scale: 0.85,
            duration: 0.6,
            ease: "power2.inOut"
        }, 0)
        .to(videoFullPoster, {
            opacity: 0,
            duration: 0.8,
            ease: "power2.inOut"
        }, 0.4)
        .to(videoFullVideo, {
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
            onComplete: () => {
                videoStarted = true;
                videoFullVideo.play();
            }
        }, 1);

    const togglePlayPause = () => {
        if (videoFullVideo.paused) {
            playCursor.classList.add("stop-cursor");
            videoFullPoster.classList.add('video-no-transition');
            videoFullUs.classList.add('video-no-transition');
            videoFullPlay.classList.add('video-no-transition');
            videoTl.play();

            if(videoStarted) {
                videoFullVideo.play(); 
            }
        } else {
            videoFullVideo.pause();
            playCursor.classList.remove("stop-cursor");
            videoTl.pause();
        }
    };

    const handleVideoEnded = () => {
        playCursor.classList.remove("stop-cursor");
        videoFullVideo.currentTime = 0;
        videoTl.reverse();
    };
    
    if (videoElement) {
        videoElement.addEventListener('click', togglePlayPause);
        videoElementMedia.addEventListener('ended', handleVideoEnded);
    }

    if (!document.pictureInPictureEnabled) {
        console.warn('Picture-in-Picture is not supported by this browser.');
        return;
    }

    const requestPiP = async () => {
        try {
            if (!document.pictureInPictureElement && !videoFullVideo.paused) {
                await videoFullVideo.requestPictureInPicture();
            }
        } catch (error) {
            console.error('Error requesting Picture-in-Picture:', error);
        }
    };

    const observerOptions = {
        root: null, // viewport
        //threshold: 0.7 // 70% of the video is visible
    };

    const observerCallback = (entries) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                // Video is less than 70% visible
                requestPiP();
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(videoElement);
};

// Export the video function as default
export default video;
